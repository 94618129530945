import React, { lazy } from 'react'
import Image from './Image'
import { LocalizedLink as Link } from '../../plugins/gatsby-theme-i18n-custom'
const Newsletter = lazy(() => pMinDelay(import('./Newsletter'), 4000))
import Obfuscate from 'react-obfuscate'
import pMinDelay from 'p-min-delay'
import { useTranslation } from 'react-i18next'

const Footer = ({ classList, type }) => {
    const isSSR = typeof window === 'undefined'
    const year = new Date().getFullYear()

    const { t } = useTranslation()

    return (
        <>
            {/*Footer*/}
            <footer className="footer pt-5 pb-4 pb-lg-5">
                <div className="container pt-lg-4">
                    <div className="row pb-5">
                        <div className="col-lg-5 col-md-7">
                            <div className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
                                <Image
                                    src="favicon.png"
                                    loadFrom="logo"
                                    alt=""
                                    style={{ width: '47px', height: 'auto' }}
                                />
                            </div>
                            <p className="fs-sm mb-2">
                                {t('Bitalo.com wird betrieben von der Bitalo AG.')}
                            </p>
                            {/*<form className="needs-validation" noValidate>*/}
                            {/*    <label htmlFor="subscr-email" className="form-label">*/}
                            {/*        Subscribe to our newsletter*/}
                            {/*    </label>*/}
                            {/*    <div className="input-group">*/}
                            {/*        <input*/}
                            {/*            type="email"*/}
                            {/*            id="subscr-email"*/}
                            {/*            className="form-control rounded-start ps-5"*/}
                            {/*            placeholder="Your email"*/}
                            {/*            required*/}
                            {/*        />*/}
                            {/*        <i className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>*/}
                            {/*        <div className="invalid-tooltip position-absolute top-100 start-0">*/}
                            {/*            Please provide a valid email address.*/}
                            {/*        </div>*/}
                            {/*        <button type="submit" className="btn btn-primary">*/}
                            {/*            Subscribe*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</form>*/}
                            {!isSSR && <Newsletter />}
                        </div>
                        <div className="col-lg-7 col-md-5 pt-4 pt-md-1 pt-lg-0">
                            <div id="footer-links" className="row">
                                <div className="col-lg-4">
                                    <h6 className="mb-2">Bitalo.com</h6>
                                    <div>
                                        <ul className="nav flex-column pb-lg-1 mb-lg-3">
                                            <li className="nav-item">
                                                <Link
                                                    to="/"
                                                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                                                >
                                                    Home
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/investor"
                                                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                                                >
                                                    {t('Anleger')}
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/business"
                                                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                                                >
                                                    {t('Unternehmen')}
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/partner"
                                                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                                                >
                                                    {t('Partner')}
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/magazine"
                                                    className="nav-link d-inline-block px-0 pt-1 pb-2"
                                                >
                                                    {t('Magazin')}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-3">
                                    <h6 className="mb-2">{t('Standort')}</h6>
                                    <p>
                                        Bitalo Aktiengesellschaft
                                        <br />
                                        Neuburger Str. 8<br />
                                        67157 Wachenheim
                                        <br />
                                        {t('Deutschland')}
                                    </p>
                                </div>
                                <div className="col-xl-4 col-lg-5 pt-2 pt-lg-0">
                                    <h6 className="mb-2">{t('Kontakt')}</h6>
                                    <Obfuscate
                                        linkText="#"
                                        email="support@bitalo.de"
                                        className="fw-medium d-block text-start mb-2"
                                    />
                                    <Obfuscate
                                        linkText="#"
                                        tel="+496322305550"
                                        className="fw-medium d-block text-start"
                                    >
                                        +49 6322 30555-0
                                    </Obfuscate>
                                    <Link
                                        to="/complaint"
                                        className="fw-semibold d-inline-block px-0 pt-1 pb-2"
                                    >
                                        {t('Beschwerdeformular')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-2 justify-content-between fs-sm">
                        <div className="col-md mb-2">
                            <p className="text-center text-md-start mb-0">
                                &copy; All rights reserved - {year}. Bitalo AG
                            </p>
                        </div>
                        <div className="col-md-auto text-center text-md-start">
                            <Link className="text-gray-800" to="/imprint/" language="de">
                                {t('Impressum')}
                            </Link>{' '}
                            |&nbsp;
                            <Link className="text-gray-800" to="/privacy/" language="de">
                                {t('Datenschutz')}
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
